<template>
  <div>
    <b-modal
      id="add-modal"
      ref="add-modal"
      title="เพิ่มอุปกรณ์เสริม"
      no-close-on-backdrop
      size="md"
      hide-footer
    >
      <validation-observer ref="addAccessories">
        <b-form @submit.prevent="addAccessories">
          <!-- ชื่อเสริม -->
          <b-form-group label="ชื่ออุปกรณ์เสริม">
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ชื่อในสัญญา -->
          <b-form-group label="ชื่อในสัญญา">
            <validation-provider
              #default="{ errors }"
              name="Lease Name"
              rules="required"
            >
              <b-form-input
                v-model="form.lease_name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ราคาในสัญญา -->
          <b-form-group label="ราคาในสัญญา">
            <validation-provider
              #default="{ errors }"
              name="Lease Price"
              rules="required"
            >
              <b-form-input
                v-model="form.lease_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Is GoPro -->
          <b-form-group label="แสดงในสัญญา">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.active"
                name="some-radios5"
                :value="true"
              >
                แสดง
              </b-form-radio>
              <b-form-radio
                v-model="form.active"
                name="some-radios5"
                :value="false"
              >
                ซ่อน
              </b-form-radio>
            </div>
          </b-form-group>

          <hr />
          <div class="text-right justify">
            <b-button variant="relief-secondary" class="mr-1" @click="cancel">
              Cancel
            </b-button>
            <b-button
              v-if="!loading"
              variant="relief-success"
              @click="validationForm"
            >
              เพิ่มอุปกรณ์
            </b-button>
            <b-button
              variant="relief-success"
              disabled
              class="mr-1"
              v-if="loading"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
  },
  data() {
    return {
      form: {
        active: true,
      },
      required,
      loading: false,
    };
  },
  props: {
    only_close: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    validationForm() {
      this.$refs.addAccessories.validate().then((success) => {
        this.$http({
          method: "POST",
          url: "/accessories",
          data: this.form,
        }).then((x) => {
          this.$refs["add-modal"].hide();
          if (this.only_close === false) {
            this.$router.push("/accessories/view/" + x.data.data);
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "เพิ่มอุปกรณ์",
              icon: "CheckIcon",
              text: "เพิ่มอุปกรณ์สำเร็จ",
              variant: "success",
            },
          });
        });
      });
    },
    cancel() {
      this.$refs["add-modal"].hide();
    },
  },
};
</script>
